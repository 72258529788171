import _isNumber from "is-number";
import _kindOf from "kind-of";
import _mathRandom from "math-random";
var exports = {};
var isNumber = _isNumber;
var typeOf = _kindOf;
var mathRandom = _mathRandom;
/**
 * Expose `randomatic`
 */

exports = randomatic;
exports.isCrypto = !!mathRandom.cryptographic;
/**
 * Available mask characters
 */

var type = {
  lower: "abcdefghijklmnopqrstuvwxyz",
  upper: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
  number: "0123456789",
  special: "~!@#$%^&()_+-={}[];',."
};
type.all = type.lower + type.upper + type.number + type.special;
/**
 * Generate random character sequences of a specified `length`,
 * based on the given `pattern`.
 *
 * @param {String} `pattern` The pattern to use for generating the random string.
 * @param {String} `length` The length of the string to generate.
 * @param {String} `options`
 * @return {String}
 * @api public
 */

function randomatic(pattern, length, options) {
  if (typeof pattern === "undefined") {
    throw new Error("randomatic expects a string or number.");
  }

  var custom = false;

  if (arguments.length === 1) {
    if (typeof pattern === "string") {
      length = pattern.length;
    } else if (isNumber(pattern)) {
      options = {};
      length = pattern;
      pattern = "*";
    }
  }

  if (typeOf(length) === "object" && length.hasOwnProperty("chars")) {
    options = length;
    pattern = options.chars;
    length = pattern.length;
    custom = true;
  }

  var opts = options || {};
  var mask = "";
  var res = ""; // Characters to be used

  if (pattern.indexOf("?") !== -1) mask += opts.chars;
  if (pattern.indexOf("a") !== -1) mask += type.lower;
  if (pattern.indexOf("A") !== -1) mask += type.upper;
  if (pattern.indexOf("0") !== -1) mask += type.number;
  if (pattern.indexOf("!") !== -1) mask += type.special;
  if (pattern.indexOf("*") !== -1) mask += type.all;
  if (custom) mask += pattern; // Characters to exclude

  if (opts.exclude) {
    var exclude = typeOf(opts.exclude) === "string" ? opts.exclude : opts.exclude.join("");
    exclude = exclude.replace(new RegExp("[\\]]+", "g"), "");
    mask = mask.replace(new RegExp("[" + exclude + "]+", "g"), "");
    if (opts.exclude.indexOf("]") !== -1) mask = mask.replace(new RegExp("[\\]]+", "g"), "");
  }

  while (length--) {
    res += mask.charAt(parseInt(mathRandom() * mask.length, 10));
  }

  return res;
}

;
export default exports;
export const isCrypto = exports.isCrypto;